import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { useFetch } from '../../hooks';
import { Spinner } from '../../components';
import { defGetParams } from '../../utils/paramSet';
import { COUPON_HISTORY } from '../../utils/column/columnSet';

const CouponHistory = () => {
  
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { response, isFetching, onRequest } = useFetch(defGetParams('coupon', 'history'));

  const onPageChange = (pageNo, _) => onRequest(defGetParams('coupon', 'history', pageNo));

  useEffect(() => {
    if (!response)
      return;

    setDataSource(response.data);
    setTotalCount(response.totalCount);
  }, [response]);

  const paginationOption = {
    total: totalCount,
    defaultCurrent: 1,
    defaultPageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange
  }

  return (
    <div className='container-coupon'>
      <div className='title-bar title'>
        <HistoryOutlined /> &nbsp;쿠폰 사용 내역
      </div>
      <div className='content'>
        <Spinner isFetching={isFetching} />
        <Table dataSource={dataSource.map((v, i) => ({ ...v, key: i }))} columns={COUPON_HISTORY} 
          pagination={{ ...paginationOption }} />
      </div>
    </div>
  );
};

export default CouponHistory;