import { Menu } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;

const Aside = () => {
  return (
    <div className='wrapper-aside'>
      <Menu defaultSelectedKeys={['11']} defaultOpenKeys={['sub1']} mode='inline'>
        <SubMenu key='sub1' icon={<BookOutlined />} title='쿠폰 관리'>
          <Menu.Item key='11'><Link to='/couponList'>쿠폰 리스트</Link></Menu.Item>
          <Menu.Item key='12'><Link to='/couponHistory'>쿠폰 사용 내역</Link></Menu.Item>
        </SubMenu>
        <SubMenu key='sub2' icon={<BookOutlined />} title='회원 관리'>
          <Menu.Item key='21'><Link to='/userList'>회원 리스트</Link></Menu.Item>
        </SubMenu>
        <SubMenu key='sub3' icon={<BookOutlined />} title='회원 관리'>
          <Menu.Item key='31'><Link to='/test'>예비메뉴</Link></Menu.Item>
          </SubMenu>
      </Menu>
    </div>
  )
}
export default Aside;
