import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Aside, Header } from './';
import { CouponList, CouponHistory, UserList } from '../pages';
import { StorageUtil } from '../utils';
import 'antd/dist/antd.css';
import '../assets/css/AntOverride.scss';
import '../assets/css/App.scss';

const App = ({ history }) => {
  const userInfo = StorageUtil.userData();

  useEffect(() => {
    if (!userInfo) {
      StorageUtil.removeItem('userInfo');
      history.push('/login');
    }
  }, [userInfo, history]);

  return (
    <Router>
      <div className='wrapper-main'>
        <Header userInfo={userInfo} />
        <Aside />
        <div className='wrapper-content'>
          <Switch>
            <Route path='/couponList' component={CouponList} />
            <Route path='/couponHistory' component={CouponHistory} />
            <Route path='/userList' component={UserList} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
