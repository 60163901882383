import React from 'react';
import { DatePicker, Form, Input, Radio, Select } from 'antd';

const FormCoupon = ({form, onFinish, itemValue, initialValues}) => {
  return (
    <Form form={form} onFinish={onFinish} id='cre-coupon' initialValues={initialValues}>
      {!!itemValue &&
      <Form.Item label='쿠폰번호' name='couponCode'>
        <Input disabled />
      </Form.Item>}
      
      <Form.Item label='쿠폰타입' name='couponType'>
        <Radio.Group disabled={!!itemValue}>
          <Radio.Button value='1' selected>판매용</Radio.Button>
          <Radio.Button value='0'>학교용</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item label='지급대상' name='destination'>
        <Input />
      </Form.Item>

      <Form.Item label='쿠폰포인트' name='couponPoint'>
        <Select>
          <Select.Option value='1000'>1,000</Select.Option>
          <Select.Option value='3000'>3,000</Select.Option>
          <Select.Option value='5000'>5,000</Select.Option>
          <Select.Option value='10000'>10,000</Select.Option>
          <Select.Option value='20000'>20,000</Select.Option>
          <Select.Option value='30000'>30,000</Select.Option>
          <Select.Option value='33000'>33,000</Select.Option>
          <Select.Option value='40000'>40,000</Select.Option>
          <Select.Option value='50000'>50,000</Select.Option>
          <Select.Option value='100000'>100,000</Select.Option>
        </Select>
      </Form.Item>

      {!itemValue &&
      <Form.Item label='수량' name='quantity'>
        <Input maxLength='5' />
      </Form.Item>}

      <Form.Item label='등록유효기간' name='regExpDate'>
        <DatePicker />
      </Form.Item>
    </Form>
  );
};

export default FormCoupon;