import React from 'react';
import { AlignLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const CouponTitlebar = ({totalCount, onClick, onConfirm}) => {
  return (
    <div className='title-bar title'>
      <AlignLeftOutlined /> &nbsp;쿠폰 리스트 (총 {totalCount}개)
      <Button type='primary' size='small' onClick={onConfirm}>출력</Button>
      <Button type='primary' size='small' onClick={e => onClick(e, { type: 'add' })}>쿠폰생성</Button>
      <Button type='primary' size='small' onClick={e => onClick(e, { type: 'del' })}>삭제</Button>
    </div>
  );
}

export default CouponTitlebar;