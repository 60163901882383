const getColumn = (title, dataIndex, key, className = '', callback = null) => {
  return {
    title,
    dataIndex,
    key,
    className,
    render: v => {
      switch (key) {
        case 'couponCode':
          return <span onClick={e => callback(e, { type: 'mod', value: v})}>{v}</span>;
        case 'couponType':
          return v === 1 ? '판매용' : '학교용';
        case 'creDate':
          return v.split(' ')[0];
        default:
          return v;
      }
    }
  }
}

export const COUPON_LIST = callback => [
  getColumn('번호', 'cno', 'cno'),
  getColumn('쿠폰코드', 'couponCode', 'couponCode', 'coupon-code', callback),
  getColumn('쿠폰포인트', 'couponPoint', 'couponPoint'),
  getColumn('쿠폰타입', 'couponType', 'couponType'),
  getColumn('지급대상', 'destination', 'destination'),
  getColumn('사용여부', 'useYn', 'useYn'),
  getColumn('유효기간', 'regExpDate', 'regExpDate'),
  getColumn('생성일', 'creDate', 'creDate'),
];

export const COUPON_HISTORY = [
  getColumn('쿠폰코드', 'couponCode', 'couponCode', 'coupon-code'),
  getColumn('사용자 이메일', 'userEmail', 'userEmail'),
  getColumn('쿠폰타입', 'couponType', 'couponType'),
  getColumn('포인트', 'couponPoint', 'couponPoint'),
  getColumn('지급대상', 'destination', 'destination'),
  getColumn('쿠폰 등록일', 'regDate', 'regDate'),
];

export const USER_LIST = searchProps => [
  getColumn('아이디', 'userId', 'userId'),
  getColumn('이메일', 'userEmail', 'userEmail'),
  getColumn('이름', 'userNm', 'userNm'),
  {
    ...getColumn('가입일', 'regDate', 'regDate'),
    ...searchProps
  }
];
