import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Table } from 'antd';
import { AlignLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { useFetch } from '../../hooks';
import { Spinner } from '../../components';
import { defUserGetParams } from '../../utils/paramSet';
import { USER_LIST } from '../../utils/column/columnSet';
import '../../assets/css/User.scss';

const UserList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const [targetDayCount, setTargetDayCount] = useState(-1);
  const [filter, setFilter] = useState('');
  const { response, isFetching, onRequest } = useFetch(defUserGetParams());

  useEffect(() => {
    if (!response)
      return;

    console.log(response);
    setDataSource(response.list);
    setTotalCount(response.totalCount);
    setTodayCount(response.todayCount);
    !!response.targetDayCount && setTargetDayCount(response.targetDayCount);
    
  }, [response]);

  const onDateChange = (dateStr, confirm) => {
    const params = {
      url: '/user',
      method: 'GET',
      type: 'normal',
      params: {
        requestType: 0,
        regDate: dateStr,
        targetDate: dateStr,
        pageNo: 1,
        pageSize: 10
      }
    }
    setFilter(dateStr);
    setPageNo(1);
    onRequest(params);
    confirm();
  }

  const onPageChange = (pageNo, _) => {
    setPageNo(pageNo);
    onRequest(defUserGetParams(pageNo, filter));
  }

  const onReload = () => {
    setFilter('');
    setPageNo(1);
    setTargetDayCount(-1);
    onRequest(defUserGetParams());
  }

  const paginationOption = {
    total: targetDayCount > -1 ? targetDayCount : totalCount,
    current: pageNo,
    defaultCurrent: 1,
    defaultPageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange
  }

  const searchProps = () => ({
    filterDropdown: ({ _, __, confirm, ___ }) => (
      <div>
        <p>* 특정 날짜에 가입한 회원 검색</p>
        <DatePicker onChange={(_, dateStr) => onDateChange(dateStr, confirm)} placeholder='날짜 선택' />
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    render: v => v
  });

  return (
    <div>
      <div className='title-bar title'>
        <AlignLeftOutlined /> &nbsp;회원 목록
      </div>
      <div className='content'>
        <Spinner isFetching={isFetching} />
        <p className='user-count'>전체 {totalCount}명, 오늘 가입한 회원 {todayCount}명</p>
        <Button type='primary' size='small' className='btn-reload' onClick={onReload}>새로고침</Button>
        {targetDayCount > -1 && <p className='filtered-count'>검색된 회원 수 {targetDayCount}명</p>}
        <Table dataSource={dataSource.map((v, i) => ({ ...v, key: i }))} 
          columns={USER_LIST(searchProps())} pagination={{ ...paginationOption }} />
      </div>
    </div>
  );
};

export default UserList;