import axios from 'axios';
import { HttpCode } from '../';

class HttpUtil {
  requestApi = async args => {
    /**
    * @param type stringify  : headers 설정 및 data를 JSON 문자열로 변환
    * @param type data       : POST 요청
    * @param type normal     : GET 요청
    * @param type excel      : excel 다운로드
    * @param type noparam    : params가 없는 형태 - 분기하지 않음
    */
    const getApi = async (url, method, params, type) => {
      let body = {
        url: url,
        method: method
      }
      let data = {};

      if(type === 'stringify') {
        body = {
          ...body,
          headers: {
            contentType: 'application/json; charset=utf-8'
          }
        }
        data = { data: JSON.stringify(params) }
      }
      if(type === 'excel') {
        body = {
          ...body,
          responseType: 'blob',
          headers: {
            contentType: 'application/json; charset=utf-8'
          }
        }
        data = { data: params }
      }
      if(type === 'data') {
        data = { data: params }
      }
      if(type === 'normal') {
        data = { params: params }
      }
      // OFFLINE일 경우 err.response는 undefined를 반환함.
      return axios({ ...body, ...data }).then(res => res).catch(err => err.response);
    }

    const response = await getApi(args.url, args.method, args.params, args.type);

    // console.log(response);
    
    let msg = '';
    if (!response)
      msg = '데이터를 저장 또는 로드할 수 없습니다. 내부 서버 오류';
    if (response.status !== 200)
      msg = '네트워크 통신 중 오류가 발생했습니다. 오류 코드 : [' + response.status + ']';
      
    const { code } = response.data;
    if (code === HttpCode.NULL_DATA)
      msg = '데이터가 존재하지 않습니다.';
    if (code === HttpCode.FAIL)
      msg = '서버와의 통신이 원활하지 않습니다.';
    if (code === HttpCode.ID_PW_INVALIDATION)
      msg = '아이디 또는 비밀번호를 확인해주세요.';

    return { data: args.type === 'excel' ? response : response.data.data, msg };
  }
}

export default new HttpUtil();