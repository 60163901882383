import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { App } from './layout';
import { Login } from './pages';

const Root = () => {
  return (
    <Router>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/' component={App} />
      </Switch>
    </Router>
  );
};

export default Root;