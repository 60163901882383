import { useEffect, useState } from 'react';
import { Form, Modal, Table } from 'antd';
import { useFetch } from '../../hooks';
import exportFromJSON from 'export-from-json'
import moment from 'moment';
import { CouponTitlebar, SearchBox, FormCoupon } from '../';
import { Spinner } from '../../components';
import { ButtonSet, HttpUtil } from '../../utils';
import { COUPON_LIST } from '../../utils/column/columnSet';
import { defGetParams } from '../../utils/paramSet';
import '../../assets/css/Coupon.scss';

const getExpDate = () => {
  const now = new Date();
  return new Date(now.setMonth(now.getMonth() + 1));
}

const CouponList = () => {
  const defInitialValues = {
    couponCode: '',
    couponType: '1',
    regExpDate: moment(getExpDate(), 'YYYY-MM-DD'),
    couponPoint: '1000',
    destination: '',
    quantity: '1'
  }
  const [form] = Form.useForm();
  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [itemValue, setItemValue] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchType, setSearchType] = useState('destination');
  const [searched, setSearched] = useState(false);

  const { response, isFetching, isLoading, onRequest } = useFetch(defGetParams('coupon', 'list'));

  useEffect(() => {
    if (!response)
      return;

    setDataSource(response.data);
    setTotalCount(response.totalCount);
    if (response.reset) {
      setCouponModalVisible(false);
      setSelectedRowKeys([]);
      setSearchType('destination');
      form.resetFields();
      onRequest(null);
    }
  }, [response, form, isLoading, couponModalVisible, onRequest]);

  useEffect(() => {
    if (!itemValue)
      return;

    const targetInitialValues = {
      couponCode: itemValue.couponCode,
      couponType: itemValue.couponType + '',
      regExpDate: moment(itemValue.regExpDate, 'YYYY-MM-DD'),
      couponPoint: itemValue.couponPoint + '',
      destination: itemValue.destination,
      quantity: '1'
    }
    form.setFieldsValue(targetInitialValues);
    setCouponModalVisible(true);
  }, [itemValue, form]);

  const onFinish = values => {
    const numRegex= /^[0-9]/g;
    const { couponCode } = values;
    if (!couponCode && !numRegex.test(values.quantity)) {
      alert('수량은 숫자만 입력하세요.');
      return;
    }
    
    const targetParams = {
      url: '/coupon',
      method: !!couponCode ? 'PUT' : 'POST',
      type: 'normal',
      params: {
        requestType: !!couponCode ? 'modCoupon' : 'addCoupon',
        couponCode: !!couponCode ? couponCode.toUpperCase() : '',
        destination: values.destination,
        couponPoint: values.couponPoint,
        couponType: values.couponType,
        quantity: values.quantity,
        regExpDate: values.regExpDate.format('YYYY-MM-DD')
      }
    }

    onRequest(targetParams);
  }

  const deleteData = targetArr => {
    const targetParams = {
      url: '/coupon',
      method: 'DELETE',
      type: 'normal',
      params: {
        requestType: 'delCoupon',
        targetArr : targetArr
      }
    }
    onRequest(targetParams);
  }

  const onClick = (_, param) => {
    const { type } = param;
    if (type === 'mod') {
      const target = dataSource.filter(v => v.couponCode === param.value)[0];
      setItemValue(target);
    } else if (type === 'del') {
      if (!selectedRowKeys.length)
        alert('삭제할 쿠폰을 선택하세요.');
      else {
        if (!window.confirm('삭제하시겠습니까?'))
          return;

        const targetArr = selectedRowKeys.reduce((acc, v) => {
          acc += ',' + dataSource[v].cno
          return acc;
        }, '');
        deleteData(targetArr.substring(1));
      }
    } else
      setCouponModalVisible(true);
  }

  const onCancel = () => {
    setItemValue(null);
    setCouponModalVisible(false);
    form.resetFields();
    form.setFieldsValue(defInitialValues);
  }

  const onPageChange = (pageNo, _) => {
    onRequest(defGetParams('coupon', 'list', pageNo));
  }

  const onSearchTypeChange = value => setSearchType(value);

  const onSearch = value => {
    let targetParams = {
      url: '/coupon',
      method: 'GET',
      type: 'normal',
      params: {
        requestType: 'couponList',
        pageNo: 1,
        pageSize: 10
      }
    }
    
    if (!value) {
      onRequest(targetParams);
      setSearched(false);
    } else {
      targetParams = {
        ...targetParams,
        params: {
          ...targetParams.params,
          [searchType]: value
        }
      }
      setSearched(true);
      onRequest(targetParams);
    }
  }

  const onConfirm = async () => {
    // 엑셀 출력
    if (!searched) {
      alert('출력할 대상을 검색하세요.');
      return;
    }

    const searchValue = document.querySelector('.form-search .ant-input');

    const params = {
      url: '/coupon',
      method: 'GET',
      type: 'normal',
      params: {
        requestType: 'export',
        [searchType]: searchValue.value
      }
    }
    
    const res = await HttpUtil.requestApi(params);
    if (!!res.msg) {
      alert(res.msg);
      return;
    }

    const { data } = res.data;
    
    const fileName = `export_excel`;
    const exportType =  exportFromJSON.types.csv;
    
    // 파일명을 꼭 data로 해서 넘겨야 함. 안 그러면 에러 남.
    exportFromJSON({ data, fileName, exportType });
  }

  const paginationOption = {
    total: totalCount,
    defaultCurrent: 1,
    defaultPageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange
  }

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys)
  }

  const couponModalTitle = !!itemValue ? '쿠폰 수정' : '쿠폰 생성';

  return (
    <div className='container-coupon'>
      <CouponTitlebar totalCount={totalCount} onClick={onClick} onConfirm={onConfirm} />
      <div className='content'>
        <Spinner isFetching={isFetching} />
        <Table dataSource={dataSource.map((v, i) => ({ ...v, key: i }))} columns={COUPON_LIST(onClick)} 
          pagination={{ ...paginationOption }} rowSelection={rowSelection} />
        <SearchBox onSearch={onSearch} onSearchTypeChange={onSearchTypeChange} />
      </div>
      <Modal title={couponModalTitle} visible={couponModalVisible} onCancel={onCancel} 
        footer={[ButtonSet.defFormBtns(onCancel, 'cre-coupon', isLoading)]}>
        <FormCoupon form={form} onFinish={onFinish} itemValue={itemValue} initialValues={defInitialValues} />
      </Modal>
    </div>
  )
}
export default CouponList;