import React from 'react';
import { Input, Select } from 'antd';

const { Search } = Input;
const { Option } = Select;

const SearchBox = ({onSearch, onSearchTypeChange}) => {
  return (
    <div className='search-box'>
      <div className='search-select'>
        <Select defaultValue='destination' style={{ width: 120 }} onChange={onSearchTypeChange}>
          <Option value='destination'>지급대상</Option>
          <Option value='useYn'>사용여부</Option>
          <Option value='creDate'>생성일</Option>
        </Select>
      </div>
      <div className='search-input'>
        <Search onSearch={onSearch} enterButton className='form-search' />
      </div>
    </div>
  );
};

export default SearchBox;