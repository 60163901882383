import { useEffect, useState } from 'react';
import { HttpUtil } from '../utils';

const useFetch = params => {
  const [requestParams, setReqeustParams] = useState(params);
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const getData = async () => {
      if (requestParams) {
        setIsFetching(true);
        setIsLoading(true);
        const res = await HttpUtil.requestApi(requestParams);
        setIsFetching(false);
        setIsLoading(false);
        const { msg, data } = res;

        if (!!msg) {
          alert(msg);
          return;
        }
        const { method, params } = requestParams;
  
        if (params.requestType !== 'login') {
          if (method === 'POST' || method === 'PUT')
            alert('저장되었습니다.');
          if (method === 'DELETE')
            alert('삭제되었습니다.');
        }
        setResponse(data);
      } else {
        setResponse(null);
        setIsFetching(false);
        setIsLoading(false);
      }
    }
    getData();
    
  }, [requestParams]);

  const onRequest = args => setReqeustParams(args);

  const onPageChange = pageNo => {
    setReqeustParams({ ...requestParams, params: { ...requestParams.params, pageNo } });
  }

  return { response, isFetching, isLoading, onRequest, onPageChange };
}

export default useFetch;