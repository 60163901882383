export const defGetParams = (url, requestType, pageNo = 1, pageSize = 10) => ({
  url: '/' + url,
  method: 'GET',
  type: 'normal',
  params: {
    requestType,
    pageNo,
    pageSize
  }
});

export const defUserGetParams = (pageNo = 1, regDate = '', pageSize = 10) => ({
  url: '/user',
  method: 'GET',
  type: 'normal',
  params: {
    requestType: 0,
    regDate,
    pageNo,
    pageSize
  }
});