class HttpCode {
  SUCCESS = 200;
  FAIL = 999;
  ACCESS_DENIED = 601;
  NULL_DATA = 602;
  NO_RESULT = 603;

  ID_PW_INVALIDATION = 630;
}

export default new HttpCode();