import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { useFetch } from '../../hooks';
import { StorageUtil } from '../../utils';
import '../../assets/css/Login.scss';

const Login = ({ history }) => {
  const [form] = Form.useForm();

  const { response, isLoading, onRequest } = useFetch(null);

  useEffect(() => {
    if (StorageUtil.userData()) 
      history.push('/couponList');
  }, [history]);

  useEffect(() => {
    if (!response)
      return;

    StorageUtil.setItem('userInfo', { userInfo: response }, false);

    form.resetFields();
    history.push('/');

  }, [response, form, history]);

  const onFinish = values => {
    // url, method, params, type
    const params = {
      url: '/auth',
      method: 'POST',
      type: 'normal',
      params: {
        requestType: 'login',
        memberId: values.memberId, 
        memberPw: values.memberPw
      }
    }
    onRequest(params);
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }

  return (
    <div id='wrapper-login' className='clearfix'>
      <Form form={form} name='basic'  onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label='아이디' name='memberId' rules={[
          { required: true, message: '아이디를 입력하세요' }
        ]}>
          <Input />
        </Form.Item>
        <Form.Item label='패스워드' name='memberPw' rules={[
          { required: true, message: '비밀번호를 입력하세요.' }
        ]}>
          <Input.Password />
        </Form.Item>

        <Button type='primary' htmlType='submit' loading={isLoading}>로그인</Button>
      </Form>
    </div>
  );
};

export default Login;