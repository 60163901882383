import React from 'react';
import { ScaleLoader } from 'react-spinners';

const Spinner = ({ isFetching }) => {
  return (
    <div className={`spinner${isFetching ? ' show' : ''}`}>
      <ScaleLoader size={100} color={'#54BDE2'} loading={true} />
    </div>
  );
}

export default Spinner;