class StorageUtil {

  getItem = (itemName, key, isLocal) => {
    const storage = isLocal ? localStorage.getItem(itemName) : sessionStorage.getItem(itemName);
    return storage ? (JSON.parse(storage))[key] : null;
  }

  setItem = (itemName, data, isLocal) => {
    if(isLocal)
      localStorage.setItem(itemName, JSON.stringify(data));
    else
      sessionStorage.setItem(itemName, JSON.stringify(data));
  }

  removeItem = (itemName, isLocal) => {
    if(isLocal)
      localStorage.removeItem(itemName);
    else
      sessionStorage.clear();
  }

  userData = () => this.getItem('userInfo', 'userInfo', false);
}

export default new StorageUtil();